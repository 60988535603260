import React from 'react';

function Footer() {
  return (
    <footer className="footer bg-light mt-5">
      <div className="container py-4">
        <div className="row">
          
          {/* Colonna 1: Menu per Clienti */}
          <div className="col-12 col-md-3 mb-3">
            <h5>Per i Clienti</h5>
            <ul className="list-unstyled">
              <li><a href="/categorie">Categorie</a></li>
              <li><a href="/offerte">Offerte</a></li>
              <li><a href="/come-funziona">Come Funziona</a></li>
              <li><a href="/contatti">Contatti</a></li>
            </ul>
          </div>

          {/* Colonna 2: Menu per Venditori */}
          <div className="col-12 col-md-3 mb-3">
            <h5>Per i Venditori</h5>
            <ul className="list-unstyled">
              <li><a href="/sign-up-seller">Registrazione Venditori</a></li>
              <li><a href="/info-venditori">Info per Venditori</a></li>
              <li><a href="/faq-venditori">FAQ Venditori</a></li>
            </ul>
          </div>

          {/* Colonna 3: 50% con newsletter e info sito */}
          <div className="col-12 col-md-6 mb-3">
            <h5>Iscriviti alla Newsletter</h5>
            <p>Rimani aggiornato su nuove offerte e sconti.</p>
            <div className="input-group mb-3">
              <input 
                type="email" 
                className="form-control" 
                placeholder="Inserisci la tua email" 
              />
              <button className="btn btn-primary" type="button">Iscriviti</button>
            </div>
            
            <p>
              Qui potresti aggiungere un breve testo informativo su Loverlist, la mission, i contatti, ecc.
            </p>
          </div>

        </div>

        <hr />

        {/* Blocco finale con info sull'azienda */}
        <div className="text-center">
          <p className="mb-0">
            © 2025 Loverlist - Tutti i diritti riservati. | P.IVA 1234567890
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

