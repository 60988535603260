import React from 'react';
import { Link } from 'react-router-dom';

const CategoryMenu = () => {
  const categories = [
    { title: 'Elettronica', handle: 'elettronica', subcategories: ['Smartphone', 'TV', 'Computer'] },
    { title: 'Moda', handle: 'moda', subcategories: ['Uomo', 'Donna', 'Bambini'] },
    { title: 'Casa e Cucina', handle: 'casa-e-cucina', subcategories: ['Arredo', 'Elettrodomestici'] },
    { title: 'Sport', handle: 'sport', subcategories: ['Fitness', 'Outdoor', 'Accessori'] },
  ];

  return (
    <div className="row">
      {categories.map((category, index) => (
        <div key={index} className="col-6 col-md-3 mb-3">
          <div className="category-card p-3 text-center border">
            <Link to={`/categories/${category.handle}`} className="text-decoration-none">
              <h6 className="mb-2">{category.title}</h6>
              <ul className="list-unstyled">
                {category.subcategories.map((sub, subIndex) => (
                  <li key={subIndex} className="text-muted small">
                    {sub}
                  </li>
                ))}
              </ul>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryMenu;
