import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import SignUpCustomer from './pages/SignUpCustomer';
import CustomerAccount from './pages/CustomerAccount';
import SignUpSeller from './pages/SignUpSeller';
import SellerAwaitConfirmation from './pages/SellerAwaitConfirmation';
import ResetPassword from './pages/ResetPassword';
import SellerAccount from './pages/SellerAccount';
import SellerIntegration from './pages/SellerIntegration';
import SellerProducts from './pages/SellerProducts';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './pages/Logout';
import EmailVerified from './pages/EmailConfirmation';
import ResendConfirmationEmail from './pages/ResendConfirmationEmail';
import ProductGrid from './pages/ProductGrid';
import SearchResults from './pages/SearchResults';
import SellerGrid from './pages/SellerGrid';
import SellerProfilePage from './pages/SellerProfilePage';
import CategoryPage from './pages/CategoryPage';
import AllCategoriesPage from './pages/AllCategoriesPage';
import NotFoundPage from './pages/NotFoundPage';


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up-customer" element={<SignUpCustomer />} />
          <Route path="/sign-up-seller" element={<SignUpSeller />} />
          <Route path="/sign-up-seller-await-confirmation" element={<SellerAwaitConfirmation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/email-verified" element={<EmailVerified />} />
          <Route path="/resend-confirmation-email" element={<ResendConfirmationEmail />} />
          <Route path="/tutti-prodotti" element={<ProductGrid />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/seller" element={<SellerGrid />} />
          <Route path="/seller/:storeHandle" element={<SellerProfilePage />} />
          <Route path="/categories" element={<AllCategoriesPage />} />
          <Route path="/categories/:handle" element={<CategoryPage />} />

          {/* Rotte protette */}
          <Route
            path="/seller-integration"
            element={
              <ProtectedRoute allowedRoles={['seller']}>
                <SellerIntegration />
              </ProtectedRoute>
            }
          />
          <Route
            path="/seller-products"
            element={
              <ProtectedRoute allowedRoles={['seller']}>
                <SellerProducts />
              </ProtectedRoute>
            }
          />
          {/* Rotte accessibili sia ai seller che ai customer */}
          <Route
            path="/account"
            element={
              <ProtectedRoute
                allowedRoles={['seller', 'customer']}
                sellerComponent={SellerAccount}
                customerComponent={CustomerAccount}
              >
                <div>Pagina condivisa tra seller e customer</div>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
