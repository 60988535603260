import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../api';

const SellerProfilePage = () => {
  const { id, storeHandle } = useParams();
  const [seller, setSeller] = useState(null);
  const [products, setProducts] = useState([]);
  const [loadingSeller, setLoadingSeller] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 20;

  useEffect(() => {
    const fetchSeller = async () => {
      try {
        const { data } = await API.get(`/seller/${storeHandle}`);
        if (data.success) {
          setSeller(data.seller);
        }
      } catch (error) {
        console.error('Errore durante il recupero del profilo venditore:', error);
      } finally {
        setLoadingSeller(false);
      }
    };

    fetchSeller();
  }, [storeHandle]);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoadingProducts(true);
      try {
        const { data } = await API.get(`/seller/${storeHandle}/products`, {
          params: { page, size: pageSize, search: searchTerm },
        });
        if (data.success) {
          setProducts(data.products);
          setTotalPages(data.totalPages);
        }
      } catch (error) {
        console.error('Errore durante il recupero dei prodotti:', error);
      } finally {
        setLoadingProducts(false);
      }
    };

    fetchProducts();
  }, [storeHandle, page, searchTerm]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  if (loadingSeller) {
    return <div>Caricamento profilo venditore...</div>;
  }

  if (!seller) {
    return <div>Venditore non trovato.</div>;
  }

  return (
    <div className="container mt-5">
      {/* Banner con logo e informazioni */}
      <div className="card mb-4">
        <div
          className="card-img-top"
          style={{
            backgroundImage: `url(${seller.store_banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '200px',
          }}
        ></div>
        <div className="d-flex align-items-center p-3">
          <img
            src={seller.store_logo_square}
            alt={seller.store_name}
            className="rounded-circle me-3"
            style={{ width: '100px', height: '100px', objectFit: 'cover', border: '2px solid #ddd' }}
          />
          <div>
            <h2>{seller.store_name}</h2>
            <p>{seller.store_description}</p>
          </div>
        </div>
      </div>

      {/* Menu di navigazione */}
      <nav className="nav nav-tabs mb-4">
        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#products">
          Prodotti
        </button>
        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#details">
          Dettagli del negozio
        </button>
        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#contact">
          Contatta il venditore
        </button>
        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#reviews">
          Recensioni
        </button>
      </nav>

      <div className="tab-content">
        {/* Tab Prodotti */}
        <div className="tab-pane fade show active" id="products">
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Cerca tra i prodotti del venditore..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {loadingProducts ? (
            <div>Caricamento prodotti...</div>
          ) : products.length > 0 ? (
            <>
              <div className="row">
                {products.map((product) => (
                  <div className="col-md-3 mb-4" key={product.id}>
                    <div className="card h-100">
                      <img
                        src={product.main_image_url || '/path/to/default-image.jpg'}
                        alt={product.title}
                        className="card-img-top"
                        style={{ height: '150px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{product.title}</h5>
                        <p className="card-text">
                          {product.price_min !== null && product.price_max !== null
                            ? `€${product.price_min.toFixed(2)} - €${product.price_max.toFixed(2)}`
                            : product.price_min !== null
                            ? `€${product.price_min.toFixed(2)}`
                            : product.price_max !== null
                            ? `€${product.price_max.toFixed(2)}`
                            : 'N/D'}
                        </p>
                        <a href={`/product/${product.id}`} className="btn btn-primary">
                          Visualizza
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-secondary"
                  disabled={page === 1}
                  onClick={() => handlePageChange(page - 1)}
                >
                  Precedente
                </button>
                <span>
                  Pagina {page} di {totalPages}
                </span>
                <button
                  className="btn btn-secondary"
                  disabled={page === totalPages}
                  onClick={() => handlePageChange(page + 1)}
                >
                  Successiva
                </button>
              </div>
            </>
          ) : (
            <div>Nessun prodotto trovato.</div>
          )}
        </div>

        {/* Tab Dettagli del negozio */}
        <div className="tab-pane fade" id="details">
          <h3>Dettagli del negozio</h3>
          <p><strong>Indirizzo:</strong> {seller.address}, {seller.city}, {seller.zip}, {seller.province}, {seller.country}</p>
          <p><strong>Sito Web:</strong> <a href={seller.website} target="_blank" rel="noopener noreferrer">{seller.website}</a></p>
          <p><strong>Partita IVA:</strong> {seller.vat_number}</p>
          <p><strong>Codice Univoco:</strong> {seller.univocal_code}</p>
          <p><strong>PEC:</strong> {seller.pec}</p>
        </div>

        {/* Tab Contatta il venditore */}
        <div className="tab-pane fade" id="contact">
          <h3>Contatta il venditore</h3>
          <form>
            <div className="mb-3">
              <label className="form-label">Nome</label>
              <input type="text" className="form-control" />
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input type="email" className="form-control" />
            </div>
            <div className="mb-3">
              <label className="form-label">Messaggio</label>
              <textarea className="form-control" rows="5"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Invia</button>
          </form>
        </div>

        {/* Tab Recensioni */}
        <div className="tab-pane fade" id="reviews">
          <h3>Recensioni</h3>
          <p>Le recensioni del venditore verranno visualizzate qui.</p>
        </div>
      </div>
    </div>
  );
};

export default SellerProfilePage;
