import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api'; // Configura l'oggetto Axios

const SearchBar = () => {
  const categories = ['Elettronica', 'Moda', 'Casa', 'Giocattoli', 'Sport'];
  const [query, setQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Tutti');
  const [suggestions, setSuggestions] = useState([]);
  const [previewResults, setPreviewResults] = useState([]);
  const navigate = useNavigate();
  const searchBarRef = useRef(null); // Per rilevare i clic esterni
  
  // Fetch suggerimenti di completamento
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.trim()) {
        try {
          const res = await API.get('/search/suggestions', {
            params: { query },
          });
          setSuggestions(res.data.suggestions || []);
        } catch (error) {
          console.error('Errore durante il fetch dei suggerimenti:', error);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [query]);

  // Fetch anteprima risultati
  useEffect(() => {
    const fetchPreviewResults = async () => {
      if (query.trim()) {
        try {
          const res = await API.get('/search/preview', {
            params: {
              query,
              category: selectedCategory !== 'Tutti' ? selectedCategory : undefined,
            },
          });
          setPreviewResults(res.data.products || []);
        } catch (error) {
          console.error('Errore durante il fetch dei risultati di anteprima:', error);
        }
      } else {
        setPreviewResults([]);
      }
    };

    fetchPreviewResults();
  }, [query, selectedCategory]);
  
  const closeDropdowns = () => {
    setSuggestions([]);
    setPreviewResults([]);
  };
  
  // Aggiungi event listener per chiudere quando clicchi fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        closeDropdowns(); // Chiude i dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // Funzione per la ricerca completa
  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?query=${encodeURIComponent(query.trim())}&category=${encodeURIComponent(selectedCategory)}`);
      closeDropdowns(); // Chiude i dropdown
    }
  };

  // Resetta la barra di ricerca
  const handleReset = () => {
    setQuery('');
    closeDropdowns();
  };

  // Naviga a un prodotto selezionato
  const handleSelectResult = (result) => {
    navigate(`/product/${result.id}`);
    closeDropdowns(); // Chiude i dropdown
  };

  // Seleziona un suggerimento
  const handleSelectSuggestion = (suggestion) => {
    setQuery(suggestion);
    closeDropdowns(); // Chiude i suggerimenti
  };

  return (
    <div className="searchbar-container container" ref={searchBarRef}>
      <form className="searchbar-form" onSubmit={handleSearch}>
        {/* Dropdown categoria */}
        <select
          className="searchbar-category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="Tutti">Tutti</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>

        {/* Input barra di ricerca */}
        <div className="searchbar-input-wrapper">
          <input
            type="text"
            className="searchbar-input"
            placeholder="Cerca un prodotto e compara i prezzi..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query && (
            <button type="button" className="searchbar-reset" onClick={handleReset}>
              ✕
            </button>
          )}
        </div>

        {/* Pulsante cerca */}
        <button type="submit" className="searchbar-submit">
          <i className="bi bi-search">Cerca</i>
        </button>
      </form>

      {/* Suggerimenti */}
      {suggestions.length > 0 && (
        <ul className="searchbar-suggestions">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="searchbar-suggestion-item"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}

      {/* Anteprima risultati */}
      {previewResults.length > 0 && (
        <div className="searchbar-preview">
          <ul>
            {previewResults.map((result) => (
              <li
                key={result.id}
                className="searchbar-preview-item"
                onClick={() => handleSelectResult(result)}
              >
                <img src={result.image} alt={result.title} />
                <div>
                  <h6>{result.title}</h6>
                  <p>{result.price} €</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
