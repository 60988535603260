import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../api'; // Configura l'oggetto Axios

const AllCategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await API.get('/categories/root');
        setCategories(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle categorie:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <div className="text-center">Caricamento...</div>;

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Esplora le Categorie</h2>
      <div className="row">
        {categories.map((category) => (
          <div key={category.id} className="col-md-4 col-sm-6 mb-4">
            <div className="card category-card p-3">
              <Link to={`/categories/${category.category_handle}`}>
                <img
                  src={category.category_image || '/placeholder.jpg'}
                  alt={category.category_title}
                  className="card-img-top"
                  style={{ height: '200px', objectFit: 'cover', borderRadius: '8px' }}
                />
              </Link>
              <div className="card-body text-center">
                <h5 className="card-title">
                  <Link to={`/categories/${category.category_handle}`} className="text-dark">
                    {category.category_title}
                  </Link>
                </h5>
                <div className="subcategories">
                  {category.subcategories.length > 0 ? (
                    <ul className="list-unstyled">
                      {category.subcategories.map((sub) => (
                        <li key={sub.id}>
                          <Link to={`/categories/${sub.category_handle}`} className="text-primary">
                            {sub.category_title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-muted">Nessuna sottocategoria</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategoriesPage;
