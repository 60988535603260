// src/pages/ResetPassword.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../api';

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    // Recupera il token dalla query string
    const queryParams = new URLSearchParams(location.search);
    const t = queryParams.get('token');
    if (t) {
      setToken(t);
    } else {
      setMessage('Token mancante nella richiesta.');
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (newPassword !== confirmPassword) {
      setMessage('Le password non coincidono.');
      return;
    }

    if (!token) {
      setMessage('Token mancante.');
      return;
    }

    try {
      const response = await API.post('/auth/reset-password', {
        token,
        newPassword
      });

      if (response.data.success) {
        setMessage('Password reimpostata con successo! Ora puoi effettuare il login.');
        // Reindirizza alla pagina di login dopo qualche secondo
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setMessage(response.data.message || 'Errore nella reimpostazione della password.');
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore di rete.');
    }
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '500px' }}>
      <h2>Reimposta Password</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Nuova Password:</label>
          <input
            type="password"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Conferma Password:</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">Reimposta Password</button>
      </form>
    </div>
  );
}

export default ResetPassword;
