import React, { Component, createRef } from 'react';

class ProductGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      page: 1,
      hasMore: true,
      view: 'list', // Modalità iniziale: lista
      filters: {
        category: '',
        sort: 'price', // Ordinamento iniziale per prezzo
        order: 'asc',
      },
    };
    this.observer = createRef();
  }

  componentDidMount() {
    this.loadProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page || prevState.filters !== this.state.filters) {
      this.loadProducts();
    }
  }

  loadProducts = async () => {
    const { page, filters } = this.state;
    const { category, sort, order } = filters;

    try {
      const query = new URLSearchParams({
        page,
        size: 20,
        category,
        sort,
        order,
      }).toString();

      const res = await fetch(`/api/products?${query}`);
      const data = await res.json();

      if (data.success) {
        this.setState((prevState) => ({
          products: [...prevState.products, ...data.products],
          hasMore: data.hasMore,
        }));
      }
    } catch (err) {
      console.error('Errore nel caricamento dei prodotti:', err);
    }
  };

  lastProductRef = (node) => {
    if (this.observer.current) this.observer.current.disconnect();

    this.observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && this.state.hasMore) {
        this.setState((prevState) => ({ page: prevState.page + 1 }));
      }
    });

    if (node) this.observer.current.observe(node);
  };

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      filters: { ...this.state.filters, [name]: value },
      page: 1,
      products: [],
    });
  };

  toggleView = () => {
    this.setState({ view: this.state.view === 'grid' ? 'list' : 'grid' });
  };

  truncateText = (text, maxLength) =>
    text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

  render() {
    const { products, view, filters } = this.state;

    return (
      <div className="container mt-5">
        <div className="row">
          {/* Barra dei filtri */}
          <div className="col-md-3">
            <h5>Filtri</h5>
            <div className="mb-3">
              <label className="form-label">Categoria</label>
              <input
                type="text"
                className="form-control"
                name="category"
                value={filters.category}
                onChange={this.handleFilterChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Ordina per</label>
              <select
                className="form-select"
                name="sort"
                value={filters.sort}
                onChange={this.handleFilterChange}
              >
                <option value="price">Prezzo</option>
                <option value="title">Titolo</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Ordine</label>
              <select
                className="form-select"
                name="order"
                value={filters.order}
                onChange={this.handleFilterChange}
              >
                <option value="asc">Ascendente</option>
                <option value="desc">Discendente</option>
              </select>
            </div>
          </div>

          {/* Griglia dei prodotti */}
          <div className="col-md-9">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Prodotti</h5>
              <button
                className="btn btn-outline-primary"
                onClick={this.toggleView}
              >
                Vista: {view === 'grid' ? 'Lista' : 'Griglia'}
              </button>
            </div>

            <div className={`row ${view === 'list' ? 'flex-column' : ''}`}>
              {products.map((product, index) => (
                <div
                  className={`col-md-${view === 'grid' ? '4' : '12'} mb-4`}
                  key={product.id}
                  ref={index === products.length - 1 ? this.lastProductRef : null}
                >
                  <div className="card h-100">
                    <img
                      src={product.image_url}
                      alt={product.title}
                      className="card-img-top"
                      style={{ height: '200px', objectFit: 'contain' }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{product.title}</h5>
                      <p className="card-text">
                        <strong className="text-danger">€{parseFloat(product.price || 0).toFixed(2)}</strong>{' '}
                        {product.compare_at_price && (
                          <span className="text-decoration-line-through text-muted">
                            €{parseFloat(product.compare_at_price || 0).toFixed(2)}
                          </span>
                        )}
                      </p>
                      {view === 'list' && (
                        <p className="card-text">{this.truncateText(product.description, 100)}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {products.length === 0 && (
              <p className="text-center mt-4">Nessun prodotto trovato.</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductGrid;
