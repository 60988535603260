// Componente React per la griglia dei prodotti
import React from 'react';
import ProductCard from './ProductCard';

const ProductGrid = ({ products }) => (
  <div className="row">
    {products.length > 0 ? (
      products.map((product) => <ProductCard key={product.id} product={product} />)
    ) : (
      <p className="text-center">Nessun prodotto trovato.</p>
    )}
  </div>
);

export default ProductGrid;