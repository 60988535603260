import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductGrid from '../components/ProductGrid';
import API from '../api';

const CategoryPage = () => {
  const { handle } = useParams();
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    sort: 'price',
    order: 'asc',
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const res = await API.get(`/categories/${handle}`, {
          params: {
            page,
            size: 20,
            sort: filters.sort,
            order: filters.order,
          },
        });
        if (res.data.success) {
          setProducts((prev) => [...prev, ...res.data.products]);
          setHasMore(res.data.hasMore);
        }
      } catch (error) {
        console.error('Errore durante il fetch dei prodotti:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [handle, filters, page]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setProducts([]);
    setPage(1);
  };

  const loadMoreProducts = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Prodotti per categoria</h1>
      <div className="row">
        {/* Filtri */}
        <div className="col-md-3">
          <h5>Filtri</h5>
          <div className="mb-3">
            <label className="form-label">Ordina per</label>
            <select
              className="form-select"
              name="sort"
              value={filters.sort}
              onChange={handleFilterChange}
            >
              <option value="price">Prezzo</option>
              <option value="title">Titolo</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Ordine</label>
            <select
              className="form-select"
              name="order"
              value={filters.order}
              onChange={handleFilterChange}
            >
              <option value="asc">Ascendente</option>
              <option value="desc">Discendente</option>
            </select>
          </div>
        </div>

        {/* Griglia dei prodotti */}
        <div className="col-md-9">
          <ProductGrid products={products} />
          {loading && <p>Caricamento...</p>}
          {hasMore && !loading && (
            <button className="btn btn-primary mt-3" onClick={loadMoreProducts}>
              Carica altri prodotti
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
