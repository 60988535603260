import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StoreSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 480, settings: { slidesToShow: 2 } },
    ],
  };

  const stores = [
    { name: 'Store 1', logo: '/media/stores/store1.png', link: '/store/store1' },
    { name: 'Store 2', logo: '/media/stores/store2.png', link: '/store/store2' },
    { name: 'Store 3', logo: '/media/stores/store3.png', link: '/store/store3' },
    // Altri store...
  ];

  return (
    <Slider {...sliderSettings} className="store-slider">
      {stores.map((store, index) => (
        <div key={index} className="text-center">
          <a href={store.link}>
            <img src={store.logo} alt={store.name} className="img-fluid" style={{ maxHeight: '80px' }} />
          </a>
        </div>
      ))}
    </Slider>
  );
};

export default StoreSlider;
