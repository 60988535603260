import React from 'react';
import { useNavigate } from 'react-router-dom';

function CheckEmail() {
  const navigate = useNavigate();

  const handleResendEmail = () => {
    navigate('/resend-confirmation-email');
  };

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h2>Controlla la tua email</h2>
        <p className="mt-3">
          Ti abbiamo inviato un'email di conferma. Controlla la tua casella di posta elettronica e segui il link per attivare il tuo account.
        </p>
        <p>
          Se non hai ricevuto l'email, clicca il pulsante qui sotto per inviarla nuovamente.
        </p>
        <button className="btn btn-primary mt-3" onClick={handleResendEmail}>
          Invia nuovamente l'email di conferma
        </button>
      </div>
    </div>
  );
}

export default CheckEmail;
