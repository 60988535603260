// src/pages/SellerAccount.js
import React, { useEffect, useState } from 'react';
import API from '../api';
import axios from 'axios';

function SellerAccount() {
  const [sellerInfo, setSellerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingSection, setEditingSection] = useState(null); // Sezione attualmente in modifica
  const [formData, setFormData] = useState({
    respName: '',
    respLastName: '',
    storeName: '',
    sellerHandle: '',
    storeDescription: '',
    address: '',
    city: '',
    zip: '',
    province: '',
    country: '',
    website: '',
    businessName: '',
    vatNumber: '',
    univocalCode: '',
    pec: ''
  });
  const [files, setFiles] = useState({
    visuraDoc: null,
    identityDoc: null,
    storeLogoSquare: null,
    storeLogoHorizontal: null,
    storeBanner: null
  });
  const [uploadStatus, setUploadStatus] = useState({
    visuraDoc: { uploading: false, error: null },
    identityDoc: { uploading: false, error: null },
    storeLogoSquare: { uploading: false, error: null },
    storeLogoHorizontal: { uploading: false, error: null },
    storeBanner: { uploading: false, error: null }
  });
  const [deleteStatus, setDeleteStatus] = useState({
    visuraDoc: { deleting: false, error: null },
    identityDoc: { deleting: false, error: null },
    storeLogoSquare: { deleting: false, error: null },
    storeLogoHorizontal: { deleting: false, error: null },
    storeBanner: { deleting: false, error: null }
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchSellerInfo = async () => {
      try {
        const res = await API.get('/auth/seller-additional-info');
        if (res.data.success && res.data.sellerInfo) {
          setSellerInfo(res.data.sellerInfo);
          setFormData({
            id: res.data.sellerInfo.id || '',
            user_id: res.data.sellerInfo.user_id || '',
            respName: res.data.sellerInfo.respName || '',
            respLastName: res.data.sellerInfo.respLastName || '',
            storeName: res.data.sellerInfo.storeName || '',
            sellerHandle: res.data.sellerInfo.sellerHandle || '',
            storeDescription: res.data.sellerInfo.storeDescription || '',
            address: res.data.sellerInfo.address || '',
            city: res.data.sellerInfo.city || '',
            zip: res.data.sellerInfo.zip || '',
            province: res.data.sellerInfo.province || '',
            country: res.data.sellerInfo.country || '',
            website: res.data.sellerInfo.website || '',
            businessName: res.data.sellerInfo.businessName || '',
            vatNumber: res.data.sellerInfo.vatNumber || '',
            univocalCode: res.data.sellerInfo.univocalCode || '',
            pec: res.data.sellerInfo.pec || '',
            storeLogoSquare: res.data.sellerInfo.storeLogoSquare || '',
            storeLogoHorizontal: res.data.sellerInfo.storeLogoHorizontal || '',
            storeBanner: res.data.sellerInfo.storeBanner || '',
          });
        }
      } catch (error) {
        console.error('Errore nel recupero dei dati del venditore:', error);
        setMessage('Errore nel recupero dei dati del venditore.');
      } finally {
        setLoading(false);
      }
    };

    fetchSellerInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFiles(prev => ({ ...prev, [name]: files[0] }));
      setUploadStatus(prev => ({ ...prev, [name]: { uploading: false, error: null } }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const handleize = (str) => str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
      const sellerHandle = `${formData.user_id}-${handleize(formData.storeName)}`;
      
      const data = { ...formData, sellerHandle };
      const res = await API.post('/auth/seller-additional-info', data);

      if (res.data.success) {
        setMessage('Dati aggiornati con successo.');
        setSellerInfo(res.data.sellerInfo);
        setEditingSection(null);
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore di rete o server');
    }
  };

  // Funzione per caricare un file specifico
  const uploadFile = async (fileType) => {
    if (!files[fileType]) {
      setMessage(`Nessun file selezionato per ${fileType}.`);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append(fileType, files[fileType]);

    try {
      setUploadStatus(prev => ({ ...prev, [fileType]: { uploading: true, error: null } }));
      let endpoint = '';
      switch (fileType) {
        case 'storeLogoSquare':
          endpoint = '/auth/upload-store-logo-square';
          break;
        case 'storeLogoHorizontal':
          endpoint = '/auth/upload-store-logo-horizontal';
          break;
        case 'storeBanner':
          endpoint = '/auth/upload-store-banner';
          break;
        case 'visuraDoc':
          endpoint = '/auth/upload-visura-doc';
          break;
        case 'identityDoc':
          endpoint = '/auth/upload-identity-doc';
          break;
        default:
          setMessage('Tipo di file non riconosciuto.');
          setUploadStatus(prev => ({ ...prev, [fileType]: { uploading: false, error: 'Tipo di file non riconosciuto.' } }));
          return;
      }

      const res = await API.post(endpoint, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.data.success) {
        setSellerInfo(prev => ({
          ...prev,
          [fileType]: res.data[fileType] || prev[fileType]
        }));
        setMessage(`${fileType} caricato con successo.`);
        setFiles(prev => ({ ...prev, [fileType]: null }));
      } else {
        setMessage(res.data.message);
        setUploadStatus(prev => ({ ...prev, [fileType]: { uploading: false, error: res.data.message } }));
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore durante il caricamento del file.');
      setUploadStatus(prev => ({ ...prev, [fileType]: { uploading: false, error: error.response?.data?.message || 'Errore durante il caricamento del file.' } }));
    }
  };

  // Funzione per cancellare un file specifico
  const deleteFile = async (fileType) => {
    try {
      setDeleteStatus(prev => ({ ...prev, [fileType]: { deleting: true, error: null } }));
      let endpoint = '';
      switch (fileType) {
        case 'storeLogoSquare':
          endpoint = '/auth/remove-store-logo-square';
          break;
        case 'storeLogoHorizontal':
          endpoint = '/auth/remove-store-logo-horizontal';
          break;
        case 'storeBanner':
          endpoint = '/auth/remove-store-banner';
          break;
        case 'visuraDoc':
          endpoint = '/auth/remove-visura-doc';
          break;
        case 'identityDoc':
          endpoint = '/auth/remove-identity-doc';
          break;
        default:
          setMessage('Tipo di file non riconosciuto.');
          setDeleteStatus(prev => ({ ...prev, [fileType]: { deleting: false, error: 'Tipo di file non riconosciuto.' } }));
          return;
      }

      const res = await API.post(endpoint, {});

      if (res.data.success) {
        setSellerInfo(prev => ({
          ...prev,
          [fileType]: null
        }));
        setMessage(`${fileType} rimosso con successo.`);
      } else {
        setMessage(res.data.message);
        setDeleteStatus(prev => ({ ...prev, [fileType]: { deleting: false, error: res.data.message } }));
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore durante la rimozione del file.');
      setDeleteStatus(prev => ({ ...prev, [fileType]: { deleting: false, error: error.response?.data?.message || 'Errore durante la rimozione del file.' } }));
    }
  };

  if (loading) return <div>Caricamento...</div>;

  return (
    <div className="container mt-5">
      <h2>Account Venditore</h2>
      {message && <div className="alert alert-info">{message}</div>}

      {/* Informazioni Personali */}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Informazioni Personali</h5>
          <button className="btn btn-secondary btn-sm" onClick={() => setEditingSection(editingSection === 'personal' ? null : 'personal')}>
            {editingSection === 'personal' ? 'Annulla' : 'Modifica'}
          </button>
        </div>
        <div className="card-body">
          {editingSection === 'personal' ? (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Nome Responsabile:</label>
                <input
                  type="text"
                  className="form-control"
                  name="respName"
                  value={formData.respName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Cognome Responsabile:</label>
                <input
                  type="text"
                  className="form-control"
                  name="respLastName"
                  value={formData.respLastName}
                  onChange={handleChange}
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary">Salva</button>
              <button type="button" className="btn btn-secondary ms-2" onClick={() => setEditingSection(null)}>Annulla</button>
            </form>
          ) : (
            <div>
              <p><strong>Nome Responsabile:</strong> {sellerInfo.respName}</p>
              <p><strong>Cognome Responsabile:</strong> {sellerInfo.respLastName}</p>
            </div>
          )}
        </div>
      </div>
      
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Integrazione</h5>
          <a 
            href="/seller-products" 
            className="btn btn-secondary"
          >
            Visualizza i prodotti integrati
          </a>
          <a 
            href="/seller-integration" 
            className="btn btn-primary"
          >
            Gestisci integrazione
          </a>
        </div>
      </div>

      
      {/* Informazioni Negozio */}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Informazioni Negozio</h5>
          <button className="btn btn-secondary btn-sm" onClick={() => setEditingSection(editingSection === 'store' ? null : 'store')}>
            {editingSection === 'store' ? 'Annulla' : 'Modifica'}
          </button>
        </div>
        <div className="card-body">
          {editingSection === 'store' ? (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Nome Negozio:</label>
                <input
                  type="text"
                  className="form-control"
                  name="storeName"
                  value={formData.storeName}
                  onChange={handleChange}
                  required
                />
              </div>
               <div className="mb-3">
                <label className="form-label">Descrizione Negozio:</label>
                <textarea
                  className="form-control"
                  name="storeDescription"
                  value={formData.storeDescription}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Indirizzo:</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Città:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">CAP:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Provincia:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="province"
                    value={formData.province}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Paese:</label>
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Sito Web:</label>
                <input
                  type="url"
                  className="form-control"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
                            
              <button type="submit" className="btn btn-primary">Salva</button>
              <button type="button" className="btn btn-secondary ms-2" onClick={() => setEditingSection(null)}>Annulla</button>
            </form>
          ) : (
            <div>
              <p><strong>Nome Negozio:</strong> {sellerInfo.storeName}</p>
              <p><strong>Descrizione Negozio:</strong> {sellerInfo.storeDescription}</p>
              <p><strong>Indirizzo:</strong> {sellerInfo.address}</p>
              <p><strong>Città:</strong> {sellerInfo.city}</p>
              <p><strong>CAP:</strong> {sellerInfo.zip}</p>
              <p><strong>Provincia:</strong> {sellerInfo.province}</p>
              <p><strong>Paese:</strong> {sellerInfo.country}</p>
              <p><strong>Sito Web:</strong> {sellerInfo.website}</p>
            </div>
          )}
        </div>
      </div>
      {/* Immagini */}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Immagini</h5>
          <button className="btn btn-secondary btn-sm" onClick={() => setEditingSection(editingSection === 'images' ? null : 'images')}>
            {editingSection === 'images' ? 'Annulla' : 'Modifica'}
          </button>
        </div>
        <div className="card-body">
          {editingSection === 'images' ? (
            <form>
              {/* Logo Negozio Quadrato */}
              <div className="mb-3">
                <label className="form-label">Logo Negozio Quadrato:</label>
                <input
                  type="file"
                  className="form-control"
                  name="storeLogoSquare"
                  accept="image/*,application/pdf"
                  onChange={handleFileChange}
                />
                {files.storeLogoSquare && (
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={() => uploadFile('storeLogoSquare')}
                    disabled={uploadStatus.storeLogoSquare.uploading}
                  >
                    {uploadStatus.storeLogoSquare.uploading ? 'Caricamento...' : 'Carica Logo Quadrato'}
                  </button>
                )}
                {uploadStatus.storeLogoSquare.error && (
                  <div className="text-danger mt-2">{uploadStatus.storeLogoSquare.error}</div>
                )}
                {sellerInfo.storeLogoSquare && (
                  <div className="mt-2">
                    {sellerInfo.storeLogoSquare.endsWith('.pdf') ? (
                      <a href={sellerInfo.storeLogoSquare} target="_blank" rel="noopener noreferrer" className="me-2">Visualizza Logo Quadrato</a>
                    ) : (
                      <img src={sellerInfo.storeLogoSquare} alt="Logo Quadrato" style={{ width: '100px', display: 'block', marginBottom: '10px' }} />
                    )}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteFile('storeLogoSquare')}
                      disabled={deleteStatus.storeLogoSquare.deleting}
                    >
                      {deleteStatus.storeLogoSquare.deleting ? 'Rimuovendo...' : 'Rimuovi'}
                    </button>
                    {deleteStatus.storeLogoSquare.error && (
                      <div className="text-danger mt-1">{deleteStatus.storeLogoSquare.error}</div>
                    )}
                  </div>
                )}
              </div>

              {/* Logo Negozio Orizzontale */}
              <div className="mb-3">
                <label className="form-label">Logo Negozio Orizzontale:</label>
                <input
                  type="file"
                  className="form-control"
                  name="storeLogoHorizontal"
                  accept="image/*,application/pdf"
                  onChange={handleFileChange}
                />
                {files.storeLogoHorizontal && (
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={() => uploadFile('storeLogoHorizontal')}
                    disabled={uploadStatus.storeLogoHorizontal.uploading}
                  >
                    {uploadStatus.storeLogoHorizontal.uploading ? 'Caricamento...' : 'Carica Logo Orizzontale'}
                  </button>
                )}
                {uploadStatus.storeLogoHorizontal.error && (
                  <div className="text-danger mt-2">{uploadStatus.storeLogoHorizontal.error}</div>
                )}
                {sellerInfo.storeLogoHorizontal && (
                  <div className="mt-2">
                    {sellerInfo.storeLogoHorizontal.endsWith('.pdf') ? (
                      <a href={sellerInfo.storeLogoHorizontal} target="_blank" rel="noopener noreferrer" className="me-2">Visualizza Logo Orizzontale</a>
                    ) : (
                      <img src={sellerInfo.storeLogoHorizontal} alt="Logo Orizzontale" style={{ width: '150px', display: 'block', marginBottom: '10px' }} />
                    )}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteFile('storeLogoHorizontal')}
                      disabled={deleteStatus.storeLogoHorizontal.deleting}
                    >
                      {deleteStatus.storeLogoHorizontal.deleting ? 'Rimuovendo...' : 'Rimuovi'}
                    </button>
                    {deleteStatus.storeLogoHorizontal.error && (
                      <div className="text-danger mt-1">{deleteStatus.storeLogoHorizontal.error}</div>
                    )}
                  </div>
                )}
              </div>

              {/* Banner Negozio */}
              <div className="mb-3">
                <label className="form-label">Banner Negozio:</label>
                <input
                  type="file"
                  className="form-control"
                  name="storeBanner"
                  accept="image/*,application/pdf"
                  onChange={handleFileChange}
                />
                {files.storeBanner && (
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={() => uploadFile('storeBanner')}
                    disabled={uploadStatus.storeBanner.uploading}
                  >
                    {uploadStatus.storeBanner.uploading ? 'Caricamento...' : 'Carica Banner'}
                  </button>
                )}
                {uploadStatus.storeBanner.error && (
                  <div className="text-danger mt-2">{uploadStatus.storeBanner.error}</div>
                )}
                {sellerInfo.storeBanner && (
                  <div className="mt-2">
                    {sellerInfo.storeBanner.endsWith('.pdf') ? (
                      <a href={sellerInfo.storeBanner} target="_blank" rel="noopener noreferrer">Visualizza Banner</a>
                    ) : (
                      <img src={sellerInfo.storeBanner} alt="Banner Negozio" style={{ width: '100%', maxWidth: '400px', display: 'block', marginBottom: '10px' }} />
                    )}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteFile('storeBanner')}
                      disabled={deleteStatus.storeBanner.deleting}
                    >
                      {deleteStatus.storeBanner.deleting ? 'Rimuovendo...' : 'Rimuovi'}
                    </button>
                    {deleteStatus.storeBanner.error && (
                      <div className="text-danger mt-1">{deleteStatus.storeBanner.error}</div>
                    )}
                  </div>
                )}
              </div>

              <button className="btn btn-primary" onClick={() => setEditingSection(null)}>Salva e Indietro</button>
            </form>
          ) : (
            <div>
              {/* Logo Negozio Quadrato */}
              {sellerInfo.storeLogoSquare && (
                <div className="mb-3">
                  <strong>Logo Negozio Quadrato:</strong><br />
                  {sellerInfo.storeLogoSquare.endsWith('.pdf') ? (
                    <a href={sellerInfo.storeLogoSquare} target="_blank" rel="noopener noreferrer">Visualizza Logo Quadrato</a>
                  ) : (
                    <img src={sellerInfo.storeLogoSquare} alt="Logo Quadrato" style={{ width: '100px' }} />
                  )}
                </div>
              )}
              {!sellerInfo.storeLogoSquare && <p><strong>Logo Negozio Quadrato:</strong> N/A</p>}

              {/* Logo Negozio Orizzontale */}
              {sellerInfo.storeLogoHorizontal && (
                <div className="mb-3">
                  <strong>Logo Negozio Orizzontale:</strong><br />
                  {sellerInfo.storeLogoHorizontal.endsWith('.pdf') ? (
                    <a href={sellerInfo.storeLogoHorizontal} target="_blank" rel="noopener noreferrer">Visualizza Logo Orizzontale</a>
                  ) : (
                    <img src={sellerInfo.storeLogoHorizontal} alt="Logo Orizzontale" style={{ width: '150px' }} />
                  )}
                </div>
              )}
              {!sellerInfo.storeLogoHorizontal && <p><strong>Logo Negozio Orizzontale:</strong> N/A</p>}

              {/* Banner Negozio */}
              {sellerInfo.storeBanner && (
                <div className="mb-3">
                  <strong>Banner Negozio:</strong><br />
                  {sellerInfo.storeBanner.endsWith('.pdf') ? (
                    <a href={sellerInfo.storeBanner} target="_blank" rel="noopener noreferrer">Visualizza Banner</a>
                  ) : (
                    <img src={sellerInfo.storeBanner} alt="Banner Negozio" style={{ width: '100%', maxWidth: '400px' }} />
                  )}
                </div>
              )}
              {!sellerInfo.storeBanner && <p><strong>Banner Negozio:</strong> N/A</p>}
            </div>
          )}
        </div>
      </div>
      {/* Informazioni Aziendali */}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Informazioni Aziendali</h5>
          <button className="btn btn-secondary btn-sm" onClick={() => setEditingSection(editingSection === 'business' ? null : 'business')}>
            {editingSection === 'business' ? 'Annulla' : 'Modifica'}
          </button>
        </div>
        <div className="card-body">
          {editingSection === 'business' ? (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Nome Azienda:</label>
                <input
                  type="text"
                  className="form-control"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Partita IVA:</label>
                <input
                  type="text"
                  className="form-control"
                  name="vatNumber"
                  value={formData.vatNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Codice Univoco:</label>
                <input
                  type="text"
                  className="form-control"
                  name="univocalCode"
                  value={formData.univocalCode}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">PEC:</label>
                <input
                  type="email"
                  className="form-control"
                  name="pec"
                  value={formData.pec}
                  onChange={handleChange}
                />
              </div>

              <button type="submit" className="btn btn-primary">Salva</button>
              <button type="button" className="btn btn-secondary ms-2" onClick={() => setEditingSection(null)}>Annulla</button>
            </form>
          ) : (
            <div>
              <p><strong>Nome Azienda:</strong> {sellerInfo.businessName}</p>
              <p><strong>Partita IVA:</strong> {sellerInfo.vatNumber}</p>
              <p><strong>Codice Univoco:</strong> {sellerInfo.univocalCode}</p>
              <p><strong>PEC:</strong> {sellerInfo.pec}</p>
            </div>
          )}
        </div>
      </div>

      {/* Documenti */}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Documenti</h5>
          <button className="btn btn-secondary btn-sm" onClick={() => setEditingSection(editingSection === 'documents' ? null : 'documents')}>
            {editingSection === 'documents' ? 'Annulla' : 'Modifica'}
          </button>
        </div>
        <div className="card-body">
          {editingSection === 'documents' ? (
            <form>
              {/* Visura Documenti */}
              <div className="mb-3">
                <label className="form-label">Visura Documenti:</label>
                <input
                  type="file"
                  className="form-control"
                  name="visuraDoc"
                  accept="application/pdf, image/*"
                  onChange={handleFileChange}
                />
                {files.visuraDoc && (
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={() => uploadFile('visuraDoc')}
                    disabled={uploadStatus.visuraDoc.uploading}
                  >
                    {uploadStatus.visuraDoc.uploading ? 'Caricamento...' : 'Carica Visura'}
                  </button>
                )}
                {uploadStatus.visuraDoc.error && (
                  <div className="text-danger mt-2">{uploadStatus.visuraDoc.error}</div>
                )}
                {sellerInfo.visuraDoc && (
                  <div className="mt-2">
                    {sellerInfo.visuraDoc.endsWith('.pdf') ? (
                      <a href={sellerInfo.visuraDoc} target="_blank" rel="noopener noreferrer" className="me-2">Visualizza Visura</a>
                    ) : (
                      <img src={sellerInfo.visuraDoc} alt="Visura Documenti" style={{ width: '100px', display: 'block', marginBottom: '10px' }} />
                    )}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteFile('visuraDoc')}
                      disabled={deleteStatus.visuraDoc.deleting}
                    >
                      {deleteStatus.visuraDoc.deleting ? 'Rimuovendo...' : 'Rimuovi'}
                    </button>
                    {deleteStatus.visuraDoc.error && (
                      <div className="text-danger mt-1">{deleteStatus.visuraDoc.error}</div>
                    )}
                  </div>
                )}
              </div>

              {/* Documento di Identità */}
              <div className="mb-3">
                <label className="form-label">Documento di Identità:</label>
                <input
                  type="file"
                  className="form-control"
                  name="identityDoc"
                  accept="application/pdf, image/*"
                  onChange={handleFileChange}
                />
                {files.identityDoc && (
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={() => uploadFile('identityDoc')}
                    disabled={uploadStatus.identityDoc.uploading}
                  >
                    {uploadStatus.identityDoc.uploading ? 'Caricamento...' : 'Carica Documento'}
                  </button>
                )}
                {uploadStatus.identityDoc.error && (
                  <div className="text-danger mt-2">{uploadStatus.identityDoc.error}</div>
                )}
                {sellerInfo.identityDoc && (
                  <div className="mt-2">
                    {sellerInfo.identityDoc.endsWith('.pdf') ? (
                      <a href={sellerInfo.identityDoc} target="_blank" rel="noopener noreferrer">Visualizza Documento</a>
                    ) : (
                      <img src={sellerInfo.identityDoc} alt="Documento di Identità" style={{ width: '100px' }} />
                    )}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteFile('identityDoc')}
                      disabled={deleteStatus.identityDoc.deleting}
                    >
                      {deleteStatus.identityDoc.deleting ? 'Rimuovendo...' : 'Rimuovi'}
                    </button>
                    {deleteStatus.identityDoc.error && (
                      <div className="text-danger mt-1">{deleteStatus.identityDoc.error}</div>
                    )}
                  </div>
                )}
              </div>

              <button className="btn btn-primary" onClick={() => setEditingSection(null)}>Salva e Indietro</button>
            </form>
          ) : (
            <div>
              <p><strong>Visura Documenti:</strong> {sellerInfo.visuraDoc ? (
                sellerInfo.visuraDoc.endsWith('.pdf') ? (
                  <a href={sellerInfo.visuraDoc} target="_blank" rel="noopener noreferrer">Visualizza Visura</a>
                ) : (
                  <img src={sellerInfo.visuraDoc} alt="Visura Documenti" style={{ width: '100px' }} />
                )
              ) : 'N/A'}</p>
              <p><strong>Documento di Identità:</strong> {sellerInfo.identityDoc ? (
                sellerInfo.identityDoc.endsWith('.pdf') ? (
                  <a href={sellerInfo.identityDoc} target="_blank" rel="noopener noreferrer">Visualizza Documento</a>
                ) : (
                  <img src={sellerInfo.identityDoc} alt="Documento di Identità" style={{ width: '100px' }} />
                )
              ) : 'N/A'}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SellerAccount;
