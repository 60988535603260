import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';

const ProtectedRoute = ({ 
  children, 
  allowedRoles = [], 
  sellerComponent: SellerComponent, 
  customerComponent: CustomerComponent 
}) => {
  const { user, loading } = useContext(AuthContext);

  // Mostra un loader mentre il contesto è in caricamento
  if (loading) {
    return <div>Caricamento...</div>; // Personalizza il loader se necessario
  }

  // Reindirizza alla pagina di login se l'utente non è autenticato
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Reindirizza alla homepage se l'utente non ha il ruolo richiesto
  if (allowedRoles.length > 0 && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  // Determina quale componente rendere in base al ruolo dell'utente
  if (user.role === 'seller' && SellerComponent) {
    return <SellerComponent />;
  }

  if (user.role === 'customer' && CustomerComponent) {
    return <CustomerComponent />;
  }

  // Fallback ai children se non ci sono `sellerComponent` o `customerComponent`
  return children;
};

export default ProtectedRoute;
