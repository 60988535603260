import React from 'react';
import { useNavigate } from 'react-router-dom';

function EmailVerified() {
  const navigate = useNavigate();

  return (
    <div className="container mt-5 text-center">
      <h2>Email Verificata con Successo!</h2>
      <p>Il tuo account è stato attivato. Puoi ora accedere alla tua area venditore.</p>
      <button className="btn btn-primary" onClick={() => navigate('/seller-account')}>
        Vai al tuo account
      </button>
    </div>
  );
}

export default EmailVerified;
