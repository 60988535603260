import React, { useEffect, useState } from 'react';
import API from '../api';

function SellerIntegration() {
  const [sellerInfo, setSellerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingSection, setEditingSection] = useState(null);
  const [formData, setFormData] = useState({
    integrationMethod: '',
    feedUrl: '',
    shopifyUrl: '',
    shopifyApiToken: '',
    shopifyProductBaseUrl: '',
    prestashopUrl: '',
    prestashopApiToken: '',
    prestashopProductBaseUrl: '',
    woocommerceUrl: '',
    woocommerceApiKey: '',
    woocommerceApiSecret: '',
    woocommerceProductBaseUrl: ''
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchSellerInfo = async () => {
      try {
        const res = await API.get('/auth/seller-additional-info');
        if (res.data.success && res.data.sellerInfo) {
          setSellerInfo(res.data.sellerInfo);
          setFormData({
            integrationMethod: res.data.sellerInfo.integrationMethod || '',
            feedUrl: res.data.sellerInfo.feedUrl || '',
            shopifyUrl: res.data.sellerInfo.shopifyUrl || '',
            shopifyApiToken: res.data.sellerInfo.shopifyApiToken || '',
            shopifyProductBaseUrl: res.data.sellerInfo.shopifyProductBaseUrl || '',
            prestashopUrl: res.data.sellerInfo.prestashopUrl || '',
            prestashopApiToken: res.data.sellerInfo.prestashopApiToken || '',
            prestashopProductBaseUrl: res.data.sellerInfo.prestashopProductBaseUrl || '',
            woocommerceUrl: res.data.sellerInfo.woocommerceUrl || '',
            woocommerceApiKey: res.data.sellerInfo.woocommerceApiKey || '',
            woocommerceApiSecret: res.data.sellerInfo.woocommerceApiSecret || '',
            woocommerceProductBaseUrl: res.data.sellerInfo.woocommerceProductBaseUrl || ''
          });
        }
      } catch (error) {
        console.error('Errore nel recupero dei dati del venditore:', error);
        setMessage('Errore nel recupero dei dati del venditore.');
      } finally {
        setLoading(false);
      }
    };

    fetchSellerInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveIntegration = async () => {
    setMessage('');
    try {
      const res = await API.post('/auth/update-seller-integration-info', formData);
      if (res.data.success) {
        setMessage('Configurazione integrazione salvata con successo.');
        setSellerInfo(res.data.sellerInfo);
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore di rete o server');
    }
  };
  
  const handleCreateCategories = async () => {
    setMessage('');
    try {
      const res = await API.post('/categories/create-category');
      if (res.data.success) {
        setMessage('Creazione categorie Avviata.');
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore di rete o server');
    }
  };
  
  const handleClearIntegration = () => {
    setFormData({
      integrationMethod: '',
      feedUrl: '',
      shopifyUrl: '',
      shopifyApiToken: '',
      shopifyProductBaseUrl: '',
      prestashopUrl: '',
      prestashopApiToken: '',
      prestashopProductBaseUrl: '',
      woocommerceUrl: '',
      woocommerceApiKey: '',
      woocommerceApiSecret: '',
      woocommerceProductBaseUrl: ''
    });
    setMessage('Configurazione integrazione resettata.');
  };

  if (loading) return <div>Caricamento...</div>;

  return (
    <div className="container mt-5">
      <h2>Gestisci integrazione</h2>
      {message && <div className="alert alert-info">{message}</div>}
      
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Aggiorna Feed</h5>
          <button 
            className="btn btn-primary"
            onClick={() => fetch('/api/feed/process-feed', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
              .then(res => res.json())
              .then(data => alert(data.message))
              .catch(err => console.error('Errore:', err))}
          >
            Aggiorna Catalogo
          </button>
        </div>
      </div>
      
      {/* Configurazione Integrazione */}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Configurazione Integrazione</h5>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => setEditingSection(editingSection === 'integration' ? null : 'integration')}
          >
            {editingSection === 'integration' ? 'Annulla' : 'Modifica'}
          </button>
        </div>
        <div className="card-body">
          {editingSection === 'integration' ? (
            <form>
              {/* Metodo di integrazione */}
              <div className="mb-3">
                <label className="form-label">Metodo di Integrazione:</label>
                <select
                  className="form-select"
                  name="integrationMethod"
                  value={formData.integrationMethod}
                  onChange={handleChange}
                >
                  <option value="">Seleziona un metodo</option>
                  <option value="feed">Feed</option>
                  <option value="shopify">Shopify</option>
                  <option value="prestashop">PrestaShop</option>
                  <option value="woocommerce">WooCommerce</option>
                </select>
              </div>

              {/* Campi Condizionali */}
              {formData.integrationMethod === 'feed' && (
                <div className="mb-3">
                  <label className="form-label">Feed URL:</label>
                  <input
                    type="url"
                    className="form-control"
                    name="feedUrl"
                    value={formData.feedUrl}
                    onChange={handleChange}
                  />
                </div>
              )}

              {formData.integrationMethod === 'shopify' && (
                <>
                  <div className="mb-3">
                    <label className="form-label">Shopify URL:</label>
                    <input
                      type="url"
                      className="form-control"
                      name="shopifyUrl"
                      value={formData.shopifyUrl}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Shopify API Token:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="shopifyApiToken"
                      value={formData.shopifyApiToken}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Dominio Shopify:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="shopifyProductBaseUrl"
                      value={formData.shopifyProductBaseUrl}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              {formData.integrationMethod === 'prestashop' && (
                <>
                  <div className="mb-3">
                    <label className="form-label">PrestaShop URL:</label>
                    <input
                      type="url"
                      className="form-control"
                      name="prestashopUrl"
                      value={formData.prestashopUrl}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">PrestaShop API Token:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="prestashopApiToken"
                      value={formData.prestashopApiToken}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Url di base PrestaShop:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="prestashopProductBaseUrl"
                      value={formData.prestashopProductBaseUrl}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              {formData.integrationMethod === 'woocommerce' && (
                <>
                  <div className="mb-3">
                    <label className="form-label">WooCommerce URL:</label>
                    <input
                      type="url"
                      className="form-control"
                      name="woocommerceUrl"
                      value={formData.woocommerceUrl}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">WooCommerce API Key:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="woocommerceApiKey"
                      value={formData.woocommerceApiKey}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">WooCommerce API Secret:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="woocommerceApiSecret"
                      value={formData.woocommerceApiSecret}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Url di base prodotto Woocommerce:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="woocommerceProductBaseUrl"
                      value={formData.woocommerceProductBaseUrl}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              {/* Azioni */}
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={handleCreateCategories}
              >
                Crea Categorie
              </button>
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={handleSaveIntegration}
              >
                Salva
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClearIntegration}
              >
                Reset
              </button>
            </form>
          ) : (
            <div>
              <p><strong>Metodo di Integrazione:</strong> {sellerInfo.integrationMethod || 'N/A'}</p>
              {sellerInfo.integrationMethod === 'feed' && <p><strong>Feed URL:</strong> {sellerInfo.feedUrl}</p>}
              {sellerInfo.integrationMethod === 'shopify' && (
                <>
                  <p><strong>Shopify URL:</strong> {sellerInfo.shopifyUrl}</p>
                  <p><strong>Shopify API Token:</strong> {sellerInfo.shopifyApiToken}</p>
                  <p><strong>Dominio Shopify:</strong> {sellerInfo.shopifyProductBaseUrl}</p>
                </>
              )}
              {sellerInfo.integrationMethod === 'prestashop' && (
                <>
                  <p><strong>PrestaShop URL:</strong> {sellerInfo.prestashopUrl}</p>
                  <p><strong>PrestaShop API Token:</strong> {sellerInfo.prestashopApiToken}</p>
                  <p><strong>Url di base PrestaShop:</strong> {sellerInfo.prestashopProductBaseUrl}</p>
                </>
              )}
              {sellerInfo.integrationMethod === 'woocommerce' && (
                <>
                  <p><strong>WooCommerce URL:</strong> {sellerInfo.woocommerceUrl}</p>
                  <p><strong>WooCommerce API Key:</strong> {sellerInfo.woocommerceApiKey}</p>
                  <p><strong>WooCommerce API Secret:</strong> {sellerInfo.woocommerceApiSecret}</p>
                  <p><strong>Url di base prodotto Woocommerce:</strong> {sellerInfo.woocommerceProductBaseUrl}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SellerIntegration;
