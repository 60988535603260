import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeroSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...sliderSettings} className="hero-slider">
      <div>
        <img src="/media/banners/banner1.jpg" alt="Banner 1" className="img-fluid" />
      </div>
      <div>
        <img src="/media/banners/banner2.jpg" alt="Banner 2" className="img-fluid" />
      </div>
      <div>
        <img src="/media/banners/banner3.jpg" alt="Banner 3" className="img-fluid" />
      </div>
    </Slider>
  );
};

export default HeroSlider;
