import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import SearchBar from './SearchBar';
import wishlistIcon from '../assets/icons/wishlist.svg';
import accountIcon from '../assets/icons/account.svg';
import hamburgerIcon from '../assets/icons/hamburger.svg';

function Header() {
  const { user, logout } = useContext(AuthContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const wishlistLink = user ? '/wishlist' : '/login';
  const accountLink = user ? '/account' : '/login';

  return (
    <header className="header">
      {/* ==================== SEZIONE SUPERIORE ==================== */}
      <div className="d-none d-md-flex justify-content-between align-items-center px-3 py-2 border-bottom header-top">
        {/* Logo a sinistra */}
        <div className="header-logo">
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Logo Loverlist"
              className="logo-img"
            />
          </Link>
        </div>

        {/* Barra di ricerca al centro */}
        <div className="header-search flex-grow-1 mx-3">
          <SearchBar />
        </div>

        {/* Icone wishlist e account a destra */}
        <div className="header-icons d-flex align-items-center">
          <Link to={wishlistLink} className="me-3 header-icon">
            <img src={wishlistIcon} alt="Wishlist" style={{ width: '24px', height: '24px' }} />
          </Link>
          {user ? (
            <div
              className={`dropdown ${isDropdownOpen ? 'show' : ''}`}
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <button
                className="btn btn-light dropdown-toggle"
                id="accountDropdown"
                aria-expanded={isDropdownOpen}
              >
                <img src={accountIcon} alt="Account" style={{ width: '24px', height: '24px' }} />
              </button>
              <ul
                className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}
                aria-labelledby="accountDropdown"
                style={{ position: 'absolute' },{ right: '0px' }}
              >
                <li>
                  <Link className="dropdown-item" to="/account">
                    Profilo
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={logout}>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <Link to={accountLink} className="header-icon">
              <img src={accountIcon} alt="Account" style={{ width: '24px', height: '24px' }} />
            </Link>
          )}
        </div>
      </div>

      {/* ==================== MENU DESKTOP ==================== */}
      <div className="d-none d-md-block border-bottom py-2">
        <nav className="d-flex justify-content-center gap-4 header-nav">
          <Link to="/">Home</Link>
          <Link to="/offers">Offerte</Link>
          <Link to="/categories">Categorie</Link>
          <Link to="/contact">Contatti</Link>
        </nav>
      </div>

      {/* ==================== NAVBAR MOBILE ==================== */}
      <div className="d-md-none border-bottom px-3 py-2 d-flex justify-content-between align-items-center">
        {/* Hamburger */}
        <button className="btn btn-outline-secondary" onClick={toggleMenu}>
          <img src={hamburgerIcon} alt="Menu" style={{ width: '24px', height: '24px' }} />
        </button>

        {/* Logo al centro */}
        <div className="header-logo">
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Logo Loverlist"
              className="logo-img"
            />
          </Link>
        </div>

        {/* Icone wishlist e account a destra */}
        <div className="header-icons d-flex align-items-center">
          <Link to={wishlistLink} className="me-3 header-icon">
            <img src={wishlistIcon} alt="Wishlist" style={{ width: '24px', height: '24px' }} />
          </Link>
          {user ? (
            <button
              className="btn btn-outline-secondary"
              onClick={logout}
              style={{ width: '24px', height: '24px' }}
            >
              <img src={accountIcon} alt="Account" />
            </button>
          ) : (
            <Link to={accountLink} className="header-icon">
              <img src={accountIcon} alt="Account" style={{ width: '24px', height: '24px' }} />
            </Link>
          )}
        </div>
      </div>

      {/* Collapsible Menu Mobile */}
      {isMenuOpen && (
        <div className="d-md-none border-bottom px-3 py-2">
          <nav className="d-flex flex-column gap-2 header-nav-mobile">
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
            <Link to="/offers" onClick={toggleMenu}>
              Offerte
            </Link>
            <Link to="/categories" onClick={toggleMenu}>
              Categorie
            </Link>
            <Link to="/contact" onClick={toggleMenu}>
              Contatti
            </Link>
          </nav>
        </div>
      )}

      {/* ==================== BOTTOM SEARCH (solo mobile) ==================== */}
      <div className="d-md-none p-2 border-top">
        <SearchBar />
      </div>
    </header>
  );
}

export default Header;
