import React, { useState } from 'react';
import API from '../api';

function ResendVerification() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/auth/resend-verification', { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Errore di rete');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Richiedi una nuova verifica email</h2>
      <form onSubmit={handleResend} className="mt-3">
        <div className="mb-3">
          <label className="form-label">Email:</label>
          <input 
            type="email"
            className="form-control"
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
            required 
          />
        </div>
        <button type="submit" className="btn btn-primary">Invia</button>
      </form>

      {message && (
        <div className="alert alert-info mt-3">
          {message}
        </div>
      )}
    </div>
  );
}

export default ResendVerification;
