import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api';
import { AuthContext } from '../components/AuthContext'; // Importa il Context

function Login() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Usa la funzione `login` dal Context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const res = await API.post('/auth/login', { email, password });
      if (res.data.success) {
        const { user, token } = res.data;

        // Aggiorna lo stato globale dell'utente tramite il Context
        login(user, token);

        // Reindirizza in base al ruolo
        if (user.role === 'customer' || user.role === 'seller') {
          navigate('/account'); // Redirect alla homepage per i customer
        } else {
          navigate('/'); // Default redirect
        }
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore di rete o server');
    }
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '500px' }}>
      <h2 className="mb-4">Login Utente</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Email:</label>
          <input 
            type="email" 
            className="form-control"
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Password:</label>
          <input 
            type="password" 
            className="form-control"
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
            required 
          />
        </div>

        <button type="submit" className="btn btn-primary w-100">
          Accedi
        </button>
      </form>

      {message && (
        <div className="alert alert-danger mt-3">
          {message}
        </div>
      )}

      {/* Blocco: Non hai un account? Link alla registrazione */}
      <div className="mt-4 text-center">
        <p className="mb-0">Non hai un account?</p>
        <a href="/sign-up-customer" className="text-decoration-none fw-bold">
          Registrati ora
        </a>
      </div>
    </div>
  );
}

export default Login;
