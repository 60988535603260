import React, { createContext, useState, useEffect } from 'react';
import API from '../api';

// Creazione del Context
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Recupera lo stato dell'utente dal localStorage al primo caricamento
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const res = await API.get('/auth/verify', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data.success) {
          setUser(res.data.user);
          localStorage.setItem('user', JSON.stringify(res.data.user)); // Aggiorna il localStorage
        } else {
          localStorage.removeItem('user');
          setUser(null);
        }
      } catch (error) {
        console.error('Errore nella verifica del token:', error);
        localStorage.removeItem('user');
        setUser(null);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const login = (userData, token) => {
    localStorage.setItem('token', token); // Salva il token
    localStorage.setItem('user', JSON.stringify(userData)); // Salva l'utente
    setUser(userData); // Aggiorna lo stato dell'utente
  };

  const logout = () => {
    localStorage.removeItem('token'); // Rimuovi il token
    localStorage.removeItem('user'); // Rimuovi l'utente
    setUser(null); // Resetta lo stato dell'utente
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
