import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API from '../api';
import { useNavigate } from 'react-router-dom';

const Sellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const { data } = await API.get('/seller');
        if (data.success) {
          setSellers(data.sellers);
        }
      } catch (error) {
        console.error('Errore durante il recupero dei venditori:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  if (loading) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Tutti i Venditori</h1>
      <div className="row">
        {sellers.map((seller) => (
          <div className="col-md-3 mb-4" key={seller.id}>
            <div className="card">
              <img
                src={seller.store_logo_square}
                alt={seller.store_name}
                className="card-img-top"
                style={{ height: '150px', objectFit: 'cover' }}
              />
              <div className="card-body text-center">
                <h5 className="card-title">{seller.store_name}</h5>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    navigate(`/seller/${seller.seller_handle}`)
                  }
                >
                  Visita il Negozio
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sellers;
