// src/pages/SignUpCustomer.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api';
import ReCAPTCHA from 'react-google-recaptcha';

function SignUpCustomer() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [newsletter, setNewsletter] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [message, setMessage] = useState('');

  // Questo handleSubmit manderà anche il captchaToken al backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Le password non coincidono!');
      return;
    }

    if (!captchaToken) {
      setMessage('Per favore completa il reCAPTCHA.');
      return;
    }

    try {
      const response = await API.post('/auth/signup-customer', {
        name,
        lastName,
        email,
        password,
        newsletter,
        captchaToken
      });
      if (response.data.success) {
        // Registrazione OK => In automatico l'API fa login e restituisce user info o token
        setMessage('Registrazione completata, reindirizzamento in corso...');
        // Se l'API ritorna un token, potresti salvarlo:
        // localStorage.setItem('token', response.data.token);
        // E poi reindirizzi alla homepage
        navigate('/');
      }
    } catch (error) {
      setMessage(
        error.response
          ? error.response.data.message
          : 'Errore di rete, riprova.'
      );
    }
  };

  // Quando l'utente risolve il captcha, Google ci dà un token
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '500px' }}>
      <h2 className="mb-4">Registrazione Cliente</h2>

      <form onSubmit={handleSubmit}>
        {/* Campo Nome */}
        <div className="mb-3">
          <label className="form-label">Nome:</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        {/* Campo Cognome */}
        <div className="mb-3">
          <label className="form-label">Cognome:</label>
          <input
            type="text"
            className="form-control"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        {/* Campo Email */}
        <div className="mb-3">
          <label className="form-label">Email:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* Check per newsletter */}
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            checked={newsletter}
            onChange={(e) => setNewsletter(e.target.checked)}
            id="newsletterCheck"
          />
          <label className="form-check-label" htmlFor="newsletterCheck">
            Iscrivimi alla newsletter
          </label>
        </div>
        {/* Campo Password */}
        <div className="mb-3">
          <label className="form-label">Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {/* Conferma Password */}
        <div className="mb-3">
          <label className="form-label">Conferma Password:</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>

        {/* RECAPTCHA */}
        <div className="mb-3">
          <ReCAPTCHA
            sitekey="6LcjTrgqAAAAAARpF7ZadLrB7z6g3ZiTNS_0DMtu"
            onChange={handleCaptchaChange}
          />
        </div>

        <button type="submit" className="btn btn-success w-100">
          Registrati
        </button>
      </form>

      {message && <div className="alert alert-info mt-3">{message}</div>}
    </div>
  );
}

export default SignUpCustomer;
