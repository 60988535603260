// src/pages/SellerAccount.js
import React, { useEffect, useState } from 'react';
import API from '../api';

function SellerAccount() {
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50); // Pagina di default con 50 prodotti
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    // Recupera i prodotti del venditore con paginazione
    const fetchSellerProducts = async () => {
      setProductsLoading(true);
      setMessage('');
      try {
        const res = await API.get('/auth/seller-products', {
          params: { page, size },
        });
        if (res.data.success) {
          setProducts(res.data.products);
          setTotalProducts(res.data.total); // Totale prodotti
          setTotalPages(res.data.totalPages); // Numero totale di pagine
        } else {
          setMessage('Errore nel recupero dei prodotti.');
        }
      } catch (error) {
        console.error('Errore nel recupero dei prodotti:', error);
        setMessage('Errore nel recupero dei prodotti.');
      } finally {
        setProductsLoading(false);
      }
    };

    fetchSellerProducts();
  }, [page, size]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Prodotti pubblicati</h2>
      {message && <div className="alert alert-info">{message}</div>}

      {/* Conteggio totale prodotti */}
      <div className="mb-3">
        <h5>
          Totale prodotti: <strong>{totalProducts}</strong>
        </h5>
      </div>

      {/* Prodotti del Venditore */}
      <div className="card mb-4">
        <div className="card-header">
          <h5>Prodotti</h5>
        </div>
        <div className="card-body">
          {productsLoading ? (
            <p>Caricamento prodotti...</p>
          ) : products.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Immagine</th>
                  <th>Titolo</th>
                  <th>Prezzo</th>
                  <th>Quantità</th>
                  <th>Dettagli</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>{product.id}</td>
                    <td>
                      <img
                        src={product.main_image_url}
                        alt={product.title}
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                      />
                    </td>
                    <td>{product.title}</td>
                    <td>
                      €{parseFloat(product.price_min).toFixed(2)} - €
                      {parseFloat(product.price_max).toFixed(2)}
                    </td>
                    <td>{product.stock}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => (window.location.href = `/product-details/${product.id}`)}
                      >
                        Dettagli
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Nessun prodotto disponibile.</p>
          )}
          {/* Paginazione */}
          <div className="pagination d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary"
              disabled={page === 1}
              onClick={() => handlePageChange(page - 1)}
            >
              Precedente
            </button>
            <span className="mx-2">
              Pagina {page} di {totalPages} ({products.length} prodotti visualizzati)
            </span>
            <button
              className="btn btn-secondary"
              disabled={page === totalPages}
              onClick={() => handlePageChange(page + 1)}
            >
              Successiva
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerAccount;
