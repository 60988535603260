import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api';
import ReCAPTCHA from 'react-google-recaptcha';

function SignUpSeller() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Le password non coincidono!');
      return;
    }

    if (!captchaToken) {
      setMessage('Completa il reCAPTCHA.');
      return;
    }

    try {
      const response = await API.post('/auth/signup-seller', { email, password, captchaToken });
      if (response.data.success) {
        const userId = response.data.userId;
        navigate('/sign-up-seller-await-confirmation', { state: { userId } });
      }
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Errore di rete');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Registrazione Venditore (Step 1)</h2>
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="mb-3">
          <label className="form-label">Email:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Conferma Password:</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(token) => setCaptchaToken(token)}
          />
        </div>

        <button type="submit" className="btn btn-success">Prosegui</button>
      </form>

      {message && <div className="alert alert-info mt-3">{message}</div>}
    </div>
  );
}

export default SignUpSeller;
