import React, { useEffect, useState } from 'react';
import API from '../api';

const CustomerAccount = () => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await API.get('/customer/account'); // Chiama l'API per ottenere i dati del cliente
        if (response.data.success) {
          setCustomer(response.data.customer);
        } else {
          setError(response.data.message || 'Errore durante il recupero dei dati');
        }
      } catch (err) {
        console.error('Errore durante il recupero delle informazioni del cliente:', err);
        setError('Errore durante il recupero dei dati');
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  if (loading) return <div>Caricamento...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="container mt-5">
      <h1>Benvenuto</h1>
      <p><strong>Email:</strong> {customer.email}</p>
    </div>
  );
};

export default CustomerAccount;
