import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../components/SearchBar';

const NotFoundPage = () => {
  return (
    <div className="not-found-page text-center d-flex flex-column align-items-center justify-content-center vh-100">
      {/* Immagine di placeholder */}
      <img
        src="https://via.placeholder.com/600x400?text=Pagina+non+trovata"
        alt="Pagina non trovata"
        className="img-fluid mb-4"
        style={{ maxWidth: '500px' }}
      />

      {/* Testo della pagina */}
      <h1 className="display-4 text-danger mb-3">404 - Pagina non trovata</h1>
      <p className="mb-4">
        Sembra che la pagina che stai cercando non esista o sia stata rimossa. Prova a cercare un altro prodotto o torna alla homepage.
      </p>

      {/* Barra di ricerca */}
      <div className="search-bar-container w-100" style={{ maxWidth: '600px' }}>
        <SearchBar />
      </div>

      {/* Link per tornare alla homepage */}
      <Link to="/" className="btn btn-primary mt-4">
        Torna alla homepage
      </Link>
    </div>
  );
};

export default NotFoundPage;
