import React from 'react';
import ProductSlider from '../components/ProductSlider'; // Slider per i prodotti
import CategoryMenu from '../components/CategoryMenu'; // Menù delle categorie
import StoreSlider from '../components/StoreSlider'; // Slider per i negozi
import BlogSection from '../components/BlogSection'; // Sezione articoli del blog
import HeroSlider from '../components/HeroSlider'; // Slider hero top

const HomePage = () => {
  return (
    <main className="home-page container">
      {/* Hero Slider */}
      <section className="hero-section mb-4">
        <HeroSlider />
      </section>

      {/* Menù Categorie */}
      <section className="categories-menu mb-4">
        <h2 className="text-center mb-3">Scopri le Categorie</h2>
        <CategoryMenu />
      </section>

      {/* Offerte in Evidenza */}
      <section className="featured-deals mb-4">
        <h2 className="text-center mb-3">Offerte in Evidenza</h2>
        <ProductSlider categoryHandle="offerte" sliderTitle="Offerte Speciali" />
      </section>

      {/* Slider per i Brand */}
      <section className="brands-slider mb-4">
        <h2 className="text-center mb-3">I Nostri Brand</h2>
        <div className="d-flex flex-wrap justify-content-center gap-3">
          <a href="/brand/nike" className="brand-link">
            <img src="/media/brands/nike.png" alt="Nike" />
          </a>
          <a href="/brand/adidas" className="brand-link">
            <img src="/media/brands/adidas.png" alt="Adidas" />
          </a>
          <a href="/brand/apple" className="brand-link">
            <img src="/media/brands/apple.png" alt="Apple" />
          </a>
          {/* Altri brand */}
        </div>
      </section>

      {/* Slider per Negozi */}
      <section className="store-slider mb-4">
        <h2 className="text-center mb-3">I Migliori Negozi</h2>
        <StoreSlider />
      </section>

      {/* Ultimi Articoli del Blog */}
      <section className="blog-articles mb-4">
        <h2 className="text-center mb-3">Dal Nostro Blog</h2>
        <BlogSection />
      </section>

      {/* Slider Prodotti per Categoria */}
      <section className="category-sliders mb-4">
        <h2 className="text-center mb-3">Scopri per Categoria</h2>
        <ProductSlider categoryHandle="elettronica" sliderTitle="Elettronica" />
        <ProductSlider categoryHandle="casa-e-cucina" sliderTitle="Casa e Cucina" />
        <ProductSlider categoryHandle="sport" sliderTitle="Sport e Tempo Libero" />
      </section>
    </main>
  );
};

export default HomePage;
