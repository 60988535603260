import React, { useState, useEffect } from 'react';
import API from '../api'; // Configura l'oggetto Axios
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // Usa react-slick per lo slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductSlider = ({ categoryHandle, sliderTitle = 'Prodotti in evidenza' }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const res = await API.get(`/categories/${categoryHandle}`);
        setProducts(res.data.products || []);
      } catch (error) {
        console.error('Errore durante il recupero dei prodotti:', error);
      } finally {
        setLoading(false);
      }
    };

    if (categoryHandle) {
      fetchProducts();
    }
  }, [categoryHandle]);

  // Configurazione dello slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet e dispositivi più piccoli
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Smartphone
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="product-slider-container mt-4">
      <h3 className="text-center">{sliderTitle}</h3>
      {loading ? (
        <p className="text-center">Caricamento...</p>
      ) : products.length > 0 ? (
        <Slider {...sliderSettings}>
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <Link to={`/product/${product.id}`} className="text-decoration-none">
                <div className="card">
                  <img
                    src={product.image || '/placeholder.jpg'} // Immagine del prodotto
                    alt={product.title}
                    className="card-img-top"
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <div className="card-body">
                    <h6 className="card-title text-dark">{product.title}</h6>
                    <p className="card-text text-primary">
                      €{product.price_min.toFixed(2)} - €{product.price_max.toFixed(2)}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      ) : (
        <p className="text-center">Nessun prodotto trovato.</p>
      )}
    </div>
  );
};

export default ProductSlider;
