import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const ProductCard = ({ product }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleToggleDetails = () => {
    setShowDetails((prevState) => !prevState);
  };
  const maxCharacterLimit = 200;
  const productDetailsPopover = (
  <Popover id="product-details-popover" style={{ maxWidth: '500px' }}>
    <Popover.Header as="h3">{product.title} - Dettagli</Popover.Header>
    <Popover.Body>
      <ul style={{ padding: 0, listStyleType: 'none' }}>
        {Object.entries(product).map(([key, value]) => {
          let displayValue;

          // Gestione specifica per il campo variants
          if (Array.isArray(value)) {
            displayValue = value
              .map((variant) =>
                typeof variant === 'object'
                  ? JSON.stringify(variant, null, 2) // Converti in JSON leggibile
                  : String(variant)
              )
              .join(', ');
          } else if (typeof value === 'object' && value !== null) {
            // Converti gli oggetti in JSON leggibile
            displayValue = JSON.stringify(value, null, 2);
          } else {
            // Per tutti gli altri tipi di valore
            displayValue =
              typeof value === 'string' && value.length > maxCharacterLimit
                ? `${value.slice(0, maxCharacterLimit)}...`
                : String(value);
          }

          return (
            <li key={key}>
              <strong>{key.replace(/_/g, ' ')}:</strong> {displayValue}
            </li>
          );
        })}
      </ul>
    </Popover.Body>
  </Popover>
  );

  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <img
          src={product.main_image_url}
          alt={product.title}
          className="card-img-top"
          style={{ height: '200px', objectFit: 'cover' }}
        />
        <div className="card-body">
          <h5 className="card-title">{product.title}</h5>
          <p className="card-text">{product.brand}</p>
          <p className="card-text">{product.vendor}</p>
          <p className="card-text">
            <strong className="text-danger">
              €{parseFloat(product.price_min || 0).toFixed(2)} - €{parseFloat(product.price_max || 0).toFixed(2)}
            </strong>
            {product.compare_at_price_min && product.compare_at_price_max && (
              <span className="text-decoration-line-through text-muted">
                €{parseFloat(product.compare_at_price_min || 0).toFixed(2)} - €
                {parseFloat(product.compare_at_price_max || 0).toFixed(2)}
              </span>
            )}
          </p>
          <a
            href={product.main_product_url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary me-2"
          >
            Vai al prodotto
          </a>
          <OverlayTrigger
            trigger={['click', 'hover']}
            placement="top"
            overlay={productDetailsPopover}
          >
            <button
              className="btn btn-secondary"
              onClick={handleToggleDetails}
            >
              Dettagli
            </button>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
