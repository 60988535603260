import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Navbar from './Navbar';
function Layout({ children }) {
  return (
    <>
      <Navbar />
      <Header />
      {/* Contenuto dinamico della pagina */}
      {children}
      <Footer />
    </>
  );
}

export default Layout;
