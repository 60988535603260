// src/api.js
import axios from 'axios';

// Recupera il token dal localStorage (o da dove lo memorizzi)
const token = localStorage.getItem('token');

const API = axios.create({
  baseURL: 'https://loverlist.com/api', // Sostituisci con il tuo URL API
  headers: {
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token}` })
  }
});

// Aggiungi un interceptor per aggiornare l'header Authorization se il token cambia
API.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete config.headers['Authorization'];
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default API;
