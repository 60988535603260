import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar bg-dark text-light">
      {/* Navbar per desktop */}
      <div className="container d-none d-md-flex justify-content-between align-items-center">
        {/* Frase sulla sinistra */}
        <div className="navbar-left">
          <p className="mb-0">Benvenuto su Loverlist</p>
        </div>
        {/* Menu inline sulla destra */}
        <div className="navbar-right">
          <Link to="/" className="nav-link text-light d-inline-block">Home</Link>
          <Link to="/categories" className="nav-link text-light d-inline-block">Categorie</Link>
          <Link to="/account" className="nav-link text-light d-inline-block">Il Mio Account</Link>
        </div>
      </div>

      {/* Navbar per mobile */}
      <div className="d-flex d-md-none justify-content-center align-items-center bg-dark py-2">
        <p className="mb-0 text-light">Loverlist</p>
      </div>
    </nav>
  );
}

export default Navbar;
