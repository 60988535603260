import React, { useState, useEffect } from 'react';
import API from '../api';

const BlogSection = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const res = await API.get('/blog/articles'); // API per ottenere gli articoli
        setArticles(res.data.articles || []);
      } catch (error) {
        console.error('Errore nel recupero degli articoli del blog:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="row">
      {articles.map((article) => (
        <div key={article.id} className="col-12 col-md-4 mb-3">
          <div className="card">
            <img src={article.image} alt={article.title} className="card-img-top" />
            <div className="card-body">
              <h5 className="card-title">{article.title}</h5>
              <p className="card-text">{article.excerpt}</p>
              <a href={`/blog/${article.id}`} className="btn btn-primary">
                Leggi di più
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogSection;
