// src/pages/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Rimuovi il token da localStorage
        localStorage.removeItem('token');

        // Richiedi al backend di cancellare il cookie
        await API.post('/auth/logout');

        // Reindirizza alla pagina di login
        navigate('/login');
      } catch (error) {
        console.error('Errore nel logout:', error);
        navigate('/login'); // Anche in caso di errore, reindirizza
      }
    };

    performLogout();
  }, [navigate]);

  return <div>Logout in corso...</div>;
}

export default Logout;
